// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-language-and-tone-js": () => import("./../../../src/pages/language-and-tone.js" /* webpackChunkName: "component---src-pages-language-and-tone-js" */),
  "component---src-pages-usability-testing-js": () => import("./../../../src/pages/usability-testing.js" /* webpackChunkName: "component---src-pages-usability-testing-js" */),
  "component---src-pages-ux-laws-and-principles-js": () => import("./../../../src/pages/ux-laws-and-principles.js" /* webpackChunkName: "component---src-pages-ux-laws-and-principles-js" */),
  "component---src-pages-writing-and-research-templates-js": () => import("./../../../src/pages/writing-and-research-templates.js" /* webpackChunkName: "component---src-pages-writing-and-research-templates-js" */)
}

